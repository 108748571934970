import {
  Box,
  Button,
  Center,
  Container,
  DarkMode,
  Flex,
  Heading,
  Image,
  LightMode,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { AllowedGroups } from "features/allowed-groups/AllowedGroups";
import { trackEvent } from "features/analytics/analytics";
import { DefaultHead } from "features/default-head/DefaultHead";
import { VerticalLogoWithText } from "features/design-system/logo/VerticalLogoWithText";
import { TextLink } from "features/design-system/TextLink";
import { NofLogo } from "features/organizations/nof/NofLogo";
import { PfLogoWithText } from "features/organizations/pf/PfLogo";
import { SiteFooter } from "features/site-footer/SiteFooter";
import { SupportersLogos } from "features/supporters-logos/SupportersLogos";
import { GetServerSideProps } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useAuth } from "utils/auth/auth-utils";
import { getLoggedInUser } from "utils/auth/auth-utils.server";
import { createTexts, useTranslation } from "utils/i18n";
import { isActiveishUser } from "utils/user-utils";

const LandingPage: React.FC = () => {
  const router = useRouter();
  const { user } = useAuth();
  const { t } = useTranslation();
  const textColor = useColorModeValue("white", "black");
  useEffect(() => {
    if (!user) {
      return;
    }
    if (isActiveishUser(user)) {
      router.push("/oversikt");
    } else {
      router.push("/profil");
    }
  }, [user, router]);
  return (
    <>
      <DefaultHead
        title={t(texts.meta.title)}
        description={t(texts.meta.description)}
      />
      <Box as="main">
        <Flex
          justifyContent="center"
          alignItems="center"
          minHeight={["100vh", "75vh"]}
          backgroundImage={`radial-gradient(circle, rgba(0,0,0,0.4), rgba(0,0,0,0.1),transparent), url(/images/get-to-the-choppa-bw.jpg)`}
          backgroundPosition={["80% 60%", "60% 80%"]}
          backgroundRepeat="no-repeat"
          backgroundSize={["auto 140%", "cover"]}
          color="white"
        >
          <Container size="md" textAlign="left" paddingY={12}>
            <Flex gap={8} flexDirection={["column", "row"]} alignItems="center">
              <VerticalLogoWithText maxWidth="140px" flex={1} />
              <Box flex={1}>
                <Heading
                  as="h1"
                  size="lg"
                  marginBottom={6}
                  textAlign={["center", "left"]}
                >
                  {t(texts.hero.heading.line1)}
                  <br />
                  {t(texts.hero.heading.line2)}
                </Heading>
                <Text fontSize="lg" marginBottom={6}>
                  {t(texts.hero.description)}
                </Text>
                <Wrap justifyContent={["center", "flex-start"]}>
                  <WrapItem>
                    <Button
                      as={Link}
                      href="/sok-tilgang"
                      variant="solid"
                      colorScheme="yellow"
                      size="lg"
                    >
                      <Box>
                        {t(texts.hero.apply)}
                        <br />
                        <Box fontSize="2xs" textAlign="center">
                          {t(texts.hero.applySubText)}
                        </Box>
                      </Box>
                    </Button>
                  </WrapItem>
                  <WrapItem>
                    <Button
                      as={Link}
                      href="/logg-inn"
                      variant="outline"
                      colorScheme="white"
                      size="lg"
                    >
                      {t(texts.hero.logIn)}
                    </Button>
                  </WrapItem>
                </Wrap>
              </Box>
            </Flex>
          </Container>
        </Flex>
        <SimpleGrid
          columns={[1, 2]}
          gap={8}
          padding={6}
          minHeight="70vh"
          maxWidth="1000px"
          marginX="auto"
        >
          <Center>
            <Stack>
              <Heading>{t(texts.about.heading)}</Heading>
              {t(texts.about.description)}
              <Box paddingTop={12}>
                <Button
                  as={Link}
                  href={t(texts.about.link)}
                  variant="solid"
                  colorScheme="yellow"
                  size="lg"
                >
                  {t(texts.about.buttonText)}
                </Button>
              </Box>
            </Stack>
          </Center>
          <Center>
            <Image
              marginTop={[12, 0]}
              height={["auto", "100%"]}
              objectFit={["contain", "cover"]}
              objectPosition="50% 50%"
              src="/images/brannvesen.jpg"
              alt={t(texts.about.altText)}
              loading="lazy"
            />
          </Center>
        </SimpleGrid>
        <Box backgroundColor="brand.foggyWhite" color="brand.slateBlack">
          <SimpleGrid
            columns={[1, 1, 2]}
            gap={8}
            padding={6}
            minHeight="50vh"
            maxWidth="1000px"
            marginX="auto"
          >
            <Center>
              <Stack>
                <Heading>{t(texts.unions.heading)}</Heading>
                {t(texts.unions.description)}
                <Box paddingTop={12}>
                  <Button
                    as={Link}
                    href="/styrke-i-fellesskap"
                    variant="solid"
                    colorScheme="yellow"
                    size="lg"
                  >
                    {t(texts.unions.buttonText)}
                  </Button>
                </Box>
              </Stack>
            </Center>
            <Center>
              <Stack spacing={8}>
                <NofLogo maxWidth={["200px"]} marginX="auto" />
                <PfLogoWithText maxWidth={["100%", "400px"]} />
              </Stack>
            </Center>
          </SimpleGrid>
        </Box>
        <Box backgroundColor="brand.slateBlack" color="white">
          <DarkMode>
            <SimpleGrid
              columns={[1, 2]}
              gap={8}
              padding={6}
              minHeight="70vh"
              maxWidth="1000px"
              marginX="auto"
            >
              <Center>
                <Image
                  marginTop={[12, 0]}
                  height={["auto", "100%"]}
                  objectFit={["contain", "cover"]}
                  objectPosition="50% 50%"
                  src="/images/opra-prisen.png"
                  alt={t(texts.gratitudeFund.altText)}
                  loading="lazy"
                />
              </Center>
              <Center>
                <Stack>
                  <Heading>{t(texts.gratitudeFund.heading)}</Heading>
                  <Text fontSize="lg">
                    {t(texts.gratitudeFund.description)}
                  </Text>
                  <Box paddingTop={12}>
                    <Button
                      as={Link}
                      href={t(texts.gratitudeFund.link)}
                      variant="solid"
                      colorScheme="yellow"
                      size="lg"
                    >
                      {t(texts.gratitudeFund.buttonText)}
                    </Button>
                  </Box>
                </Stack>
              </Center>
            </SimpleGrid>
          </DarkMode>
        </Box>
        <Box background="white" color="black">
          <Box minHeight="30vh" marginX="auto" maxWidth="1200px" paddingY={6}>
            <Heading textAlign="center" as="h2" marginBottom={6}>
              {t(texts.supporters.heading)}
            </Heading>
            <Box paddingX={6} paddingBottom={12}>
              <SupportersLogos />
            </Box>
            <Center>
              <Button
                variant="outline"
                colorScheme="yellow"
                as={Link}
                href="/stottespillere"
              >
                &rarr; {t(texts.supporters.buttonText)}
              </Button>
            </Center>
          </Box>
        </Box>
        <DarkMode>
          <Box backgroundColor="brand.slateBlack" color="brand.pureWhite">
            <SimpleGrid
              columns={[1, 1]}
              gap={6}
              minHeight="70vh"
              maxWidth="1000px"
              width="100%"
              padding={6}
              marginX="auto"
            >
              <Center>
                <Stack>
                  <Heading>{t(texts.eligibleGroups.heading)}</Heading>
                  <AllowedGroups />
                  <Box paddingTop={12}>
                    <Button
                      as={Link}
                      href="/sok-tilgang"
                      variant="solid"
                      colorScheme="yellow"
                      size="lg"
                    >
                      {t(texts.eligibleGroups.buttonText)}
                    </Button>
                  </Box>
                </Stack>
              </Center>
            </SimpleGrid>
          </Box>
        </DarkMode>
        <Box backgroundColor="brand.almostWhite" color="brand.slateBlack">
          <SimpleGrid
            columns={[1, 2]}
            gap={4}
            maxWidth="1000px"
            width="100%"
            paddingTop={6}
            paddingX={6}
            marginX="auto"
          >
            <Center>
              <Stack>
                <Heading as="h2" size="xl">
                  {t(texts.appPromo.heading)}
                </Heading>
                <Text fontSize="lg">{t(texts.appPromo.description)}</Text>
                <Button
                  variant="solid"
                  colorScheme="yellow"
                  size="lg"
                  width="fit-content"
                  marginTop={6}
                  as="a"
                  href="https://apps.apple.com/no/app/opra/id6458589396"
                  onClick={() => {
                    trackEvent("app-download-clicked");
                  }}
                >
                  {t(texts.appPromo.buttonText)}
                </Button>
              </Stack>
            </Center>
            <Box>
              <Image
                src="/images/app-preview.png"
                alt="OPRA app"
                loading="lazy"
              />
            </Box>
          </SimpleGrid>
        </Box>
        <Box
          height={12}
          background="brand.almostWhite"
          display={["block", "none"]}
        />
        <SimpleGrid columns={[1, 2]} gap={4}>
          <Center>
            <Image
              height={["30vh"]}
              width="100%"
              objectFit="cover"
              objectPosition="50% 50%"
              src="/images/politi-zoom.jpg"
              display={["block", "none"]}
              loading="lazy"
              alt=""
            />
            <Image
              height={["100%"]}
              objectFit="cover"
              objectPosition="50% 50%"
              src="/images/politi.jpg"
              display={["none", "block"]}
              loading="lazy"
              alt=""
            />
          </Center>
          <Center
            minHeight={["auto", "50vh"]}
            paddingTop={[3, 12]}
            paddingBottom={12}
          >
            <Container>
              <Heading as="h2" marginBottom={3}>
                {t(texts.contact.heading)}
              </Heading>
              <Text fontSize="lg">{t(texts.contact.description)}</Text>
              <Box paddingTop={6}>
                <Button
                  as={Link}
                  href={t(texts.about.link)}
                  variant="solid"
                  colorScheme="yellow"
                  size="lg"
                >
                  {t(texts.contact.buttonText)}
                </Button>
              </Box>
            </Container>
          </Center>
        </SimpleGrid>
      </Box>
      <LightMode>
        <Box
          paddingY={12}
          backgroundColor="brand.emergencyYellow"
          color="brand.slateBlack"
        >
          <Container maxWidth="80ch" textAlign="center">
            <Stack spacing={[3, 6]}>
              <Heading>{t(texts.lastCta.heading)}</Heading>
              <Box>
                <Button
                  as={Link}
                  href="/sok-tilgang"
                  variant="solid"
                  colorScheme="teal"
                  size="lg"
                >
                  {t(texts.lastCta.buttonText)}
                </Button>
              </Box>
            </Stack>
          </Container>
        </Box>
      </LightMode>
      <SiteFooter />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const user = await getLoggedInUser(ctx);
  if (user) {
    return {
      redirect: {
        permanent: false,
        destination: isActiveishUser(user) ? "/oversikt" : "/profil",
      },
    };
  }

  return {
    props: {},
  };
};

export default LandingPage;

const texts = createTexts({
  meta: {
    title: {
      en: "OPRA - Operational Discount",
      no: "OPRA - Operativ Rabatt",
    },
    description: {
      en: "The world's best discounts for operational personnel in selected professions",
      no: "Norges beste rabatter for operativt personell i utvalgte yrker",
    },
  },
  hero: {
    heading: {
      line1: {
        en: "Europe's best discounts.",
        no: "Norges beste rabatter.",
      },
      line2: {
        en: "For those who serve.",
        no: "For operative.",
      },
    },
    apply: {
      en: "Apply for access",
      no: "Søk om tilgang",
    },
    applySubText: {
      no: "(Det er helt gratis)",
      en: "(It's completely free)",
    },
    logIn: {
      en: "Log in",
      no: "Logg inn",
    },
    description: {
      no: (
        <>
          Er du vernepliktig eller jobber i Forsvaret, Politiet, Brannvesenet,
          Ambulansetjenesten, Sivilforsvaret, frivillige redningstjenester?{" "}
          <br />
          <strong>
            Få Norges beste rabatter med OPRAs eksklusive fordelsprogram for
            operative.
          </strong>
        </>
      ),
      en: (
        <>
          Are you conscript or work in the Armed Forces, Police, Fire Service,
          Ambulance Service, Civil Protection or voluntary rescue services?{" "}
          <br />
          <strong>
            Get the best discounts in Europe with OPRA&apos;s exclusive benefit
            program for operatives.
          </strong>
        </>
      ),
    },
  },
  about: {
    heading: {
      en: (
        <>
          Europe&apos;s best discounts.
          <br />
          For those who serve.
        </>
      ),
      no: (
        <>
          Norges beste rabatter.
          <br />
          For operative.
        </>
      ),
    },
    description: {
      no: (
        <>
          <Text fontSize="lg">
            OPRA arbeider sammen med støttespillere for å øke verdsettelsen av
            samfunnsborgere som tar forhøyet risiko for å beskytte samfunnet og
            medborgere. Norge trenger deg, og OPRA gjør sitt for å øke din
            kjøpekraft.
          </Text>
          <Text fontSize="lg">Tilgang til OPRA er gratis.</Text>
        </>
      ),
      en: (
        <>
          <Text fontSize="lg">
            OPRA works with supporting businesses to increase the appreciation
            of citizens who take increased risk to protect society and fellow
            citizens. Your country needs you, and OPRA does its best to increase
            your purchasing power.
          </Text>
          <Text fontSize="lg">OPRA membership is free.</Text>
        </>
      ),
    },
    link: {
      en: "/about",
      no: "/om",
    },
    buttonText: {
      en: "Read more about OPRA",
      no: "Les mer om OPRA",
    },
    altText: {
      en: "Brannfolk i arbeid",
      no: "Fire fighers in action",
    },
  },
  unions: {
    heading: {
      no: "Styrke i fellesskap",
      en: "Strength in unity",
    },
    description: {
      no: (
        <Text fontSize="lg">
          OPRA samarbeider med organisasjoner som representerer operative i
          Norge for å kunne tilby gode avtaler til tjenestegjørende og
          veteraner.
        </Text>
      ),
      en: (
        <Text fontSize="lg">
          OPRA collaborates with organizations that represent those who serve in
          Norway to be able to offer good deals to those who serve and veterans.
        </Text>
      ),
    },
    buttonText: {
      no: "Les mer om samarbeidet",
      en: "Read more about the collaboration",
    },
  },
  gratitudeFund: {
    heading: {
      en: "OPRA Gratitude Fund",
      no: "OPRA takknemlighetsfond",
    },
    description: {
      no: `OPRA takknemlighetsfond arbeider for å hedre enkeltpersoner
    som har gjort en innsats utover det som kan forventes i
    tjenesten.`,
      en: `The OPRA Gratitude Fund works to honor individuals who have
    made an effort beyond what can be expected in service.`,
    },
    buttonText: {
      no: "Les mer om fondet",
      en: "Read more about the fund",
    },
    link: {
      no: "/takknemlighetsfond",
      en: "/opra-foundation",
    },
    altText: {
      no: "OPRA-prisen",
      en: "The OPRA award",
    },
  },
  supporters: {
    heading: {
      no: "Noen av OPRAs støttespillere",
      en: "Some of OPRA's supporters",
    },
    buttonText: {
      no: "Se alle støttespillerne",
      en: "See all supporters",
    },
  },
  eligibleGroups: {
    heading: {
      no: "Kun tilgjengelig for berettigede grupper",
      en: "Only available for eligible groups",
    },
    buttonText: {
      no: "Søk om tilgang",
      en: "Apply for access",
    },
  },
  appPromo: {
    heading: {
      no: "Last ned appen",
      en: "Download the app",
    },
    description: {
      no: "OPRA har sin egen app for iOS (og snart Android) som gir deg en enkel oversikt over alle dine rabatter. Appen er gratis og kan lastes ned fra App Store.",
      en: "OPRA has its own app for iOS (and soon Android) that gives you an easy overview of all your discounts. The app is free and can be downloaded from the App Store.",
    },
    buttonText: {
      no: "Last ned fra App Store",
      en: "Download from the App Store",
    },
  },
  contact: {
    heading: {
      no: "Av operative. For operative.",
      en: "By operatives. For operatives.",
    },
    description: {
      no: (
        <>
          OPRA-teamet består av fast ansatte i det operative. Har du spørsmål,{" "}
          <TextLink href="/kontakt">ta kontakt</TextLink>.
        </>
      ),
      en: (
        <>
          The OPRA team consists of full-time employees in the operational. If
          you have any questions, please{" "}
          <TextLink href="/contact">contact us</TextLink>.
        </>
      ),
    },
    buttonText: {
      en: "Read more about OPRA",
      no: "Les mer om OPRA",
    },
  },
  lastCta: {
    heading: {
      no: "Er du operativ?",
      en: "Are you, or have you been, serving?",
    },
    buttonText: {
      no: "Søk om tilgang",
      en: "Apply for access",
    },
  },
});
