import { Stack, StackProps } from "@chakra-ui/react";
import {
  FeaturedList,
  FeaturedListItem,
} from "features/featured-list/FeaturedList";
import { FaArrowRight } from "react-icons/fa";
import { createTexts, useTranslation } from "utils/i18n";

export const AllowedGroups = (props: StackProps) => {
  const { t } = useTranslation();
  return (
    <Stack {...props}>
      <FeaturedList icon={FaArrowRight}>
        {texts.listItems.map((item) => (
          <FeaturedListItem
            key={t(item.title)}
            description={t(item.description)}
          >
            {t(item.title)}
          </FeaturedListItem>
        ))}
      </FeaturedList>
    </Stack>
  );
};

const texts = createTexts({
  listItems: [
    {
      title: {
        no: "Politiet",
        en: "Police",
      },
      description: {
        no: "inkludert studenter ved Politihøgskolen",
        en: "including students at the Police Academy",
      },
    },
    {
      title: {
        no: "Forsvaret",
        en: "Armed Forces",
      },
      description: {
        no: "inkludert Heimevernet og vernepliktige",
        en: "including Home Guard and conscripts",
      },
    },
    {
      title: {
        no: "Brannvesenet",
        en: "Fire Service",
      },
      description: {
        no: "",
        en: "",
      },
    },
    {
      title: {
        no: "Ambulansetjenesten",
        en: "Paramedics",
      },
      description: {
        no: "inkludert studenter i ambulansefag og paramedisin",
        en: "including students in ambulance and paramedicine",
      },
    },
    {
      title: {
        no: "Sivilforsvaret",
        en: "Civil Defense",
      },
      description: {
        no: "",
        en: "",
      },
    },
    {
      title: {
        no: "Redningsselskapet",
        en: "Redningsselskapet",
      },
      description: {
        no: "",
        en: "",
      },
    },
    {
      title: {
        no: "Frivillige redningstjenester",
        en: "Volunteer rescue services",
      },
      description: {
        no: "",
        en: "",
      },
    },
    {
      title: {
        no: "Veteraner",
        en: "Veterans",
      },
      description: {
        no: "",
        en: "",
      },
    },
  ],
});
