import { Box, Flex, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import groq from "groq";
import Link from "next/link";
import useSWR from "swr";
import { Business } from "types/domain";
import sanity, { urlFor } from "utils/sanity";

const query = groq`*[_type == "business" && isHidden != true][0...20] | order(_createdAt desc) {
  _id,
  name,
  logo,
}`;

type SanityData = Pick<Business, "_id" | "name" | "logo">[];

/** A list of our supporters' logos */
export const SupportersLogos = () => {
  const { data: supporters } = useSWR<SanityData>("supporters", () =>
    sanity.fetch(query)
  );
  if (!supporters) {
    return null;
  }
  return (
    <Box overflow="hidden" width="100%">
      <motion.div
        initial={{ x: "0" }}
        animate={{
          x: "-100%",
          transition: { ease: "linear", duration: 45, repeat: Infinity },
        }}
      >
        <Flex justifyItems="center" alignItems="center" wrap="nowrap">
          {supporters
            .filter((supporter) => supporter.logo)
            .map((supporter) => (
              <Link
                key={supporter._id}
                href={`/stottespillere/${supporter._id}`}
              >
                <Image
                  key={supporter._id}
                  src={urlFor(supporter.logo!).width(500).url() as string}
                  alt={supporter.name}
                  objectFit="contain"
                  filter="saturate(0)"
                  maxWidth="150px"
                  transitionDuration="fast"
                  transitionProperty="filter, transform"
                  loading="lazy"
                  _hover={{ filter: "saturate(1)", transform: "scale(1.1)" }}
                  _focus={{ filter: "saturate(1)", transform: "scale(1.1)" }}
                  marginX={6}
                />
              </Link>
            ))}
        </Flex>
      </motion.div>
    </Box>
  );
};
