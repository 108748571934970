import { BoxProps, Image } from "@chakra-ui/react";

export const PfLogo = (props: BoxProps) => {
  return (
    <Image
      src="/images/pf-logo.png"
      alt="Logo, Politiets Fellesforbund"
      {...props}
    />
  );
};

export const PfLogoWithText = (props: BoxProps) => {
  return (
    <Image
      src="/images/pf-logo-tekst-bakgrunn.jpg"
      alt="Logo, Politiets Fellesforbund"
      {...props}
    />
  );
};
