import { doc, updateDoc } from "firebase/firestore";
import { OpraUser } from "types/domain";
import { useAuth } from "./auth/auth-utils";
import { firestore } from "./firebase/client";

/** Maps the subscriptionStatus enum to a more user friendly string */
export const getSubscriptionStatusDetailsString = (
  status: OpraUser["subscriptionStatus"]
) => {
  switch (status) {
    case "AWAITING_DOCUMENTATION":
      return "Venter på dokumentasjon";
    case "AWAITING_MANUAL_VERIFICATION":
      return "Ligger til verifisering";
    case "ACTIVE":
      return "Aktivt medlem";
    case "DENIED_ACCESS":
      return "Avslått tilgang";
    case "BLOCKED":
      return "Blokkert";
  }
};

/** Maps the userType enum to a more user friendly string */
export const getUserTypeString = (userType: OpraUser["userType"]) => {
  switch (userType) {
    case "admin":
      return "Admin";
    case "partner":
      return "Støttespiller";
    case "regular":
      return "Medlem";
  }
};

export const isActiveishUser = (user: OpraUser | null): user is OpraUser => {
  if (!user) {
    return false;
  }
  return user.subscriptionStatus == "ACTIVE";
};

export const isAdminUser = (user: OpraUser | null): user is OpraUser => {
  if (!user) {
    return false;
  }
  return user.userType === "admin";
};

export const useChangeLanguage = () => {
  const { user } = useAuth();
  return async () => {
    if (!user) {
      return;
    }
    const { preferredLanguage } = user;

    const newLanguage = preferredLanguage === "en" ? "no" : "en";
    await updateDoc(doc(firestore, "users", user.id), {
      preferredLanguage: newLanguage,
    });
  };
};
