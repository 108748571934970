import {
  Box,
  Flex,
  SimpleGrid,
  SimpleGridProps,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { FaArrowRight } from "react-icons/fa";

const FeaturedListContext = React.createContext(FaArrowRight);

type FeaturedListProps = SimpleGridProps & {
  icon?: typeof FaArrowRight;
};
export const FeaturedList: React.FC<FeaturedListProps> = ({
  children,
  icon,
  ...rest
}) => (
  <SimpleGrid columns={[1, 2]} as="ul" listStyleType="none" {...rest}>
    <FeaturedListContext.Provider value={icon || FaArrowRight}>
      {children}
    </FeaturedListContext.Provider>
  </SimpleGrid>
);

type FeaturedListItemProps = {
  icon?: React.ComponentType;
  description?: React.ReactNode;
  children: React.ReactNode;
};
export const FeaturedListItem: React.FC<FeaturedListItemProps> = ({
  icon,
  description,
  children,
}) => {
  const inheritedIcon = React.useContext(FeaturedListContext);
  const descriptionColor = useColorModeValue("gray.700", "gray.400");
  return (
    <Flex as="li" fontSize="lg">
      <Box
        as={icon || inheritedIcon}
        color="brand.emergencyYellow"
        width="1em"
        height="1em"
        marginRight="0.5em"
        marginTop="0.5ch"
      />
      <Box>
        {children}
        {description && (
          <>
            <br />
            <Box fontSize="sm" lineHeight="xs" color={descriptionColor}>
              {description}
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
};
