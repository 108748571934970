import { Box, BoxProps } from "@chakra-ui/react";
import theme from "utils/theme";
export const VerticalLogoWithText = (props: BoxProps) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 120 166"
      width="100%"
      fill="none"
      aria-label="OPRA - Operativ rabatt"
      {...props}
    >
      <path
        d="M60 80a40 40 0 1 0 0-80 40 40 0 0 0 0 80Z"
        fill={theme.colors.brand.emergencyYellow}
      />
      <path
        d="M49 66.5c-.3-6.5-8-12.5-8-12.6l-2-1.6L60 9.8l21 42.5-2 1.6c-2.2 1.7-7.8 7.2-8 12.6l-6.3-.1c.2-7 5.4-13 8.4-15.8L60 24.2 46.9 50.6c2.9 2.8 8.2 8.8 8.4 15.8h-6.4Z"
        fill={theme.colors.brand.slateBlack}
      />
      <path
        d="M0 117c0-11.1 5.5-17 14-17s14 5.9 14 17c0 11-5.5 16.9-14 16.9s-14-6-14-17Zm22.4 2.5v-5.1c0-6-3.4-9.7-8.4-9.7s-8.4 3.7-8.4 9.7v5.1c0 6 3.4 9.7 8.4 9.7s8.4-3.7 8.4-9.7Zm12.2 13.8v-32.7h14c5.9 0 9.4 3.9 9.4 9.8 0 6-3.5 9.9-9.5 9.9h-8.7v13h-5.2Zm5.2-17.7h8.4a3.7 3.7 0 0 0 4-2.4c.2-.5.3-1 .3-1.7v-2.2a3.8 3.8 0 0 0-2.6-4c-.6-.1-1.1-.2-1.7-.1h-8.4v10.4ZM69 133.3h-5.3v-32.7h14c5.9 0 9.5 3.8 9.5 9.8 0 4.7-2.2 8-6.4 9.1l7.1 13.8h-5.8l-6.6-13.2h-6.4l-.1 13.2Zm8.4-17.7a3.7 3.7 0 0 0 4-2.4c.2-.5.3-1 .2-1.7v-2.2a3.8 3.8 0 0 0-2.6-4c-.5-.1-1-.2-1.6-.1H69v10.4h8.4Zm37.1 17.7-3-8.8h-12l-2.9 8.8h-5.4l11.1-32.7h6.6l11.1 32.7h-5.5Zm-8.9-27.9h-.2l-4.7 14.5h9.6l-4.7-14.5Z"
        fill="currentColor"
      />
      <path
        d="M120 143.9H0v3h120v-3Z"
        fill={theme.colors.brand.emergencyYellow}
      />
      <path
        d="M0 161.1c0-2.7 1.3-4.2 3.3-4.2 2 0 3.4 1.5 3.4 4.2s-1.3 4.3-3.4 4.3c-2 0-3.3-1.5-3.3-4.3Zm5.3.7v-1.3a2.2 2.2 0 0 0-.4-1.7 2 2 0 0 0-2.4-.5 2 2 0 0 0-.7.5 2.2 2.2 0 0 0-.5 1.7v1.3a2.2 2.2 0 0 0 .5 1.6 2 2 0 0 0 1.5.8c.3 0 .6 0 .9-.2l.7-.6a2.1 2.1 0 0 0 .4-1.6Zm4.7 3.4V157h3.3a2 2 0 0 1 1.6.7 2.3 2.3 0 0 1 .6 1.8 2.4 2.4 0 0 1-.6 1.8l-.7.5a2 2 0 0 1-1 .1h-2v3.3H9.9Zm1.2-4.4h2.4a.9.9 0 0 0 .5-.6c0-.1.1-.3 0-.4v-.7a1 1 0 0 0-.2-.7.9.9 0 0 0-.7-.3h-2v2.7Zm7.4 4.4V157h5v1.2h-3.8v2.3h3.4v1.1h-3.4v2.4h3.7v1.2h-5Zm9.4 0h-1.2V157h3.4a2 2 0 0 1 1.6.7 2.3 2.3 0 0 1 .6 1.8c0 .5 0 1-.3 1.4a2 2 0 0 1-1.1.9l1.7 3.4h-1.5l-1.5-3.3H28v3.3Zm2-4.4h.5a.9.9 0 0 0 .5-.6c0-.1.1-.3 0-.4v-.7a1 1 0 0 0-.2-.7.9.9 0 0 0-.7-.3h-2v2.6l2 .1Zm10.6 4.4L40 163h-2.8l-.8 2.2H35l2.7-8.2h1.6l2.6 8.2h-1.3Zm-2-7-1.2 3.7h2.3l-1.2-3.6Zm7.1 0h-2.3V157h5.9v1.2H47v7h-1.3v-7Zm6.1 7v-1h1V158h-1v-1H55v1h-1v6.2h1v1h-3.3Zm8.4 0-2.6-8.2H59l1.3 4 .7 3 .8-3 1.2-4h1.3l-2.6 8.2h-1.4Zm12.4 0h-1.3V157h3.4a2 2 0 0 1 1.6.7 2.3 2.3 0 0 1 .6 1.8c0 .5 0 1-.3 1.4a2 2 0 0 1-1.1.9l1.6 3.4h-1.4l-1.6-3.3h-1.5v3.3Zm2-4.4a.9.9 0 0 0 .7-.2 1 1 0 0 0 .3-.8v-.7a1 1 0 0 0-.3-.7.9.9 0 0 0-.7-.3h-2v2.6l2 .1Zm10.5 4.4-.7-2.2h-2.9l-.6 2.2h-1.3l2.6-8.2h1.6l2.6 8.2h-1.3Zm-2.1-7-1.1 3.7h2.3l-1.2-3.6Zm6.3-1.2h3.4l.8.1.7.5a2 2 0 0 1 .6 1.5 1.6 1.6 0 0 1-.3 1.2l-.5.4-.6.2a1.6 1.6 0 0 1 1.2.6 1.8 1.8 0 0 1 .5 1.3 2.3 2.3 0 0 1-.6 1.6l-.6.5a2 2 0 0 1-.8.2h-3.8v-8Zm1.3 3.5h1.9a.8.8 0 0 0 .7-.3l.2-.3v-.8a1 1 0 0 0-.2-.7.9.9 0 0 0-.7-.2h-2v2.3Zm0 3.6h2.1a.9.9 0 0 0 .8-.3 1 1 0 0 0 .2-.8v-.4a1 1 0 0 0-.2-.8.9.9 0 0 0-.8-.2h-2.1v2.5Zm12.4 1.1-.7-2.2h-2.9l-.7 2.2h-1.2l2.6-8.2h1.6l2.6 8.2H103Zm-2.1-7-1.1 3.7h2.2l-1.1-3.6Zm7.1 0h-2.2V157h5.9v1.2h-2.4v7h-1.2v-7Zm8.4 0H114V157h5.9v1.2h-2.3v7h-1.3v-7Z"
        fill="currentColor"
      />
    </Box>
  );
};
